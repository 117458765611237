import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import MySwiper from "../calendario/mySwiper"
import Promozioni from "../home/promozioni"
import Banner from "../common/banner"
import Movie from "../home/movie"
import calendarioStyles from "../../styles/calendario/calendario.module.scss"

const Calendario = () => {
  const data = useStaticQuery(graphql`
    query CalendarioAvventoWrapperQuery {
      allStrapiPromo {
        edges {
          node {
            codice
            fine
            inizio
            sconto
            strapiId
            trattamentos {
              nome
              slug
            }
          }
        }
      }
      strapiCalendarioAvvento(data: {eq: "sfondo"}) {
        id
        foto {
          publicURL
        }
      }
    }
  `)

   const bgURL = data.strapiCalendarioAvvento.foto.publicURL

/* 
  let today_month = new Date()
    .toLocaleDateString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(3, 5)

  let today_day = new Date()
    .toLocaleDateString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(0, 2)

  const today_promo = data.allStrapiPromo.edges.filter(
    promo =>
      parseInt(promo.node.inizio.slice(8, 10).trim()) === parseInt(today_day)
  )


  const today_sconto =
    today_promo.length === 0 ? false : today_promo[0].node.sconto
  const today_codice =
    today_promo.length === 0 ? false : today_promo[0].node.codice
  const today_trattamento =
    today_promo.length === 0 ? false : today_promo[0].node.trattamentos[0].nome
  const today_slug =
    today_promo.length === 0 ? false : today_promo[0].node.trattamentos[0].slug */


  return (
   
    <div className={`${calendarioStyles.calendario} ${calendarioStyles.natale}`}>
          <div  style={{ 
                    backgroundImage: `url(${bgURL})`, 
                    backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover"}} 
                    className={`${calendarioStyles.text} ${calendarioStyles.natale}`}>
         {/*  <div style={{ 
                    background: "linear-gradient(midnightblue, black)",//"midnightblue", 
                    color:"white", 
                    opacity: "0.7"}}  className={calendarioStyles.natale}>
            <p> Alla base delle Relazioni Umane: <b>INCONDIZIONATAMENTE</b>,<br/>  19 lettere che formano questa lunga parola dal suono libero. </p>
            <p>Che sa di bene e di rispetto, e che, tradotta nel linguaggio del Cuore significa: “ti vedo e ti voglio bene così come sei”.</p>
            <p>Perché se c'è la condizione, è già qualcos'altro. Cambia tutto, diventa un contratto, mai scritto e potenzialmente pericoloso. </p> 
            <p>Invece <b><i>INCONDIZIONATAMENTE</i></b> dà energia e vibrazione positiva, ti fa essere migliore, autentico, TE STESSO. SEMPRE. </p>
            <br/><br/> 
            {(today_month === "12") && (today_day <= "25") && (<>
            <h2 className={calendarioStyles.title} style={{padding:"1rem", fontSize: "1.4rem"}}>
              Buon Natale con il calendario dell'avvento
            </h2> </>
                )
          }
              
          {today_sconto && today_month === "12" && (
            <div className={calendarioStyles.text}>

                  <Link
                    className={calendarioStyles.details}
                    to={`/trattamenti/${today_slug}`}
                  >
                     <h2 className={calendarioStyles.title}>
                     {Number(today_day).toLocaleString()} dic
                  </h2>
    codice {" "}
                  <span className={calendarioStyles.bold}>{today_codice}</span> {" "}<br/>
                  sconto <span className={calendarioStyles.bold}>{today_sconto}€</span> {" "}
                  su <span className={calendarioStyles.bold}>{today_trattamento}</span> 
                  </Link>
            </div> 
             

          )}  */}
             <Movie/>
          </div>

    
         
            
    
        </div>
  // </div>
  )
}

export default Calendario