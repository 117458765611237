import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Banner from "../common/banner"
import Movie from "../home/movie"
import calendarioStyles from "../../styles/calendario/calendario.module.scss"
import aforismiStyles from "../../styles/pages/aforismi.module.scss"
import Img from "gatsby-image"


const Calendario2024 = () => {
  const data = useStaticQuery(graphql`
  query CalendarioAvvento1WrapperQuery {
   allStrapiCalendarioAvvento(filter: {data: {nin: ["video", "sfondo"], regex: ""}}, sort: {fields: createdAt, order: ASC}) {
     edges {
      node {
      data
        strapiId
        foto {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
       }
        trattamento {
          promo
          slug
          nome
        }
      }
    }
  }
  strapiCalendarioAvvento(data: {eq: "sfondo"}) {
        id
        foto {
         childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          publicURL
        }
  }
    }  `)

  const bgURL = data.strapiCalendarioAvvento.foto.publicURL

  let today_day = new Date()
    .toLocaleDateString("it-IT", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    })
    .slice(0, 2)

  return (

    <>
    <div className={`${calendarioStyles.calendario} ${calendarioStyles.natale}`}>
          <div  style={{ 
                    backgroundImage: `url(${bgURL})`, 
                    backgroundRepeat: "no-repeat", 
                    backgroundSize: "cover"}} 
                    className={`${calendarioStyles.text} ${calendarioStyles.natale}`}>
             <Movie/>
          </div>
        </div>
        <Banner/>
        <ul className={aforismiStyles.aforismi}>
        {data.allStrapiCalendarioAvvento.edges.map(post => 
            ((parseInt(post.node.data.slice(0, 2).trim()) <= parseInt(today_day)) ? <Link
           
                  to={`/trattamenti/${post.node.trattamento.slug}`}
                >
              <Img
                key={post.node.strapiId}
                className={aforismiStyles.foto}
                fluid={post.node.foto.childImageSharp.fluid}
                alt=""
              />  
                 </Link> 
                 :   
              <Img
                key={post.node.strapiId}
                className={aforismiStyles.foto}
                fluid={data.strapiCalendarioAvvento.foto.childImageSharp.fluid}
                alt=""
              />  
        ))}
  
      </ul>
      </>
    )
}

export default Calendario2024